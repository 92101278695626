@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@layer base {
  html {
    font-family: 'Open Sans';
    background: #fafafa;
  }
}

:root {
  --theme-color: #1ea4ce;
  --grayscale-500: #697488;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c2c2c2;
}
