.pagination {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 16px;
  margin-top: 2rem;
  user-select: none;
  color: var(--grayscale-500);
}

.pagination .page-item {
  padding: 0.5rem;
}

.pagination .page-item .page-link {
  display: block;
  padding: 0.25rem;
}

.pagination .page-item:not(.active):not(.disabled):hover {
  color: var(--theme-color);
}

.pagination .page-item.prev {
  margin-right: 3.5rem;
}

.pagination .page-item.next {
  margin-left: 3.5rem;
}

.pagination .active {
  background-color: var(--theme-color);
  color: #fff;
  border-radius: 2px;
}

.pagination .disabled .page-link {
  cursor: not-allowed;
}

@media (max-width: 540px) {
  .pagination {
    font-size: 0.75rem;
  }

  .pagination .page-item {
    padding: 0.25rem;
  }
}

@media (max-width: 1280px) {
  .pagination .page-item.prev {
    margin-right: 1.5rem;
  }

  .pagination .page-item.next {
    margin-left: 1.5rem;
  }
}

@media (max-width: 580px) {
  .pagination .page-item.prev {
    margin-right: 0.5rem;
  }

  .pagination .page-item.next {
    margin-left: 0.5rem;
  }
}

@media (max-width: 420px) {
  .pagination .page-item.prev {
    margin-right: 0.25rem;
  }

  .pagination .page-item.next {
    margin-left: 0.4rem;
  }

  .pagination .page-item {
    padding: 0.2rem;
  }
}
